import { ready } from "./utils";

ready(function () {
  const button = document.querySelector(
    ".language-selector .language-selector--button"
  );
  const overlay = document.querySelector(".language-selector-overlay");
  const content = document.querySelector(".language-selector--container");

  function hideContent() {
    button.setAttribute("aria-pressed", false);
    content.classList.add("hidden");
    overlay.classList.add("hidden");
  }

  function showContent() {
    button.setAttribute("aria-pressed", true);
    content.classList.remove("hidden");
    overlay.classList.remove("hidden");
  }

  button?.addEventListener("click", function (event) {
    const button = event.target;
    const state = button.getAttribute("aria-pressed") === "true" ? false : true;

    if (state) {
      showContent();
    } else {
      hideContent();
    }
  });

  window.addEventListener("click", function (event) {
    if (button.contains(event.target) || event.target === button) {
      return;
    }

    const isClickInside = content.contains(event.target);
    if (isClickInside) {
      return;
    }

    hideContent();
  });
});
